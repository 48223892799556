import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Layout from "../components/Layout"

export const BiographyTemplate = ({ biography }) => {
  return (
    <div className="biography__content">
      <ReactMarkdown
        source={biography.biography}
        renderers={{
          link: props => (
            <a href={props.href} target="_blank" rel="noopener noreferrer">
              {props.children}
            </a>
          )
        }}
      />
    </div>
  )
}

const Biography = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout className="biography" scroll>
      <BiographyTemplate biography={frontmatter} />
    </Layout>
  )
}

export default Biography

export const pageQuery = graphql`
  query BiographyTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "biography" } }) {
      frontmatter {
        biography
      }
    }
  }
`
